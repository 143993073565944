import React from 'react'
import {Link, graphql} from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Wrapper from '../components/wrapper'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query ErrorPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      innerImage {
      asset {
        fluid(maxHeight: 300) {
          ...GatsbySanityImageFluid_withWebp
        }
       }
      }
    }
  }
`

const NotFoundPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = (data || {}).site
  return (
    <Layout>
      <SEO title='404: Not found | Edwinteaches.com' />
      <BackgroundImage
        style={{
          backgroundPosition: 'top',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#ffffff',
          textShadow: '1px 2px 6px #000000',
          textAlign: 'center',
          height: 100
        }}
        fluid={site.innerImage.asset.fluid}
        backgroundColor={`#102514`}
      >
        <h2>Not Found</h2>
      </BackgroundImage>
      <Wrapper>
        <div style={{
          marginTop: '3rem',
          textAlign: 'center'
        }}>
          <p>The page you requested does not exist.</p>
          <br /><br />
          <Link to='/'>Go Back to the Main page</Link>
        </div>
      </Wrapper>
    </Layout>
  )
}

export default NotFoundPage
